<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>用户管理</template>
      <template v-slot:secondMenu>系统用户</template>
    </breadcrumb-nav>
    <el-card>
        <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin: 10px 0px"
        @click="showDialog"
      >添加
      </el-button>
        <el-table style="width: 100%" border :data="list">
          <el-table-column type="index" label="序号" width="80px" align="center">
          </el-table-column>
          <el-table-column prop="avatar" label="头像" width="80px">
            <template slot-scope="{ row }">
              <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
            </template>
          </el-table-column>
          <el-table-column prop="name" width="200px" label="昵称">
          </el-table-column>
          <el-table-column prop="sUrl" label="短链">
          </el-table-column>
          <el-table-column prop="prop" label="操作" width="120px">
            <template slot-scope="{row}">
              <el-row>
                <el-button
                  style="width:100px;"
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="update(row)"
                >更改</el-button>
              </el-row>
              <el-row>
                <el-button
                  style="width:100px;margin-top: 10px;"
                  type="success"
                  icon="el-icon-picture-outline"
                  size="mini"
                  @click="genQrCode(row)"
                >生成海报</el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="listQuery.page"
          :page-sizes="[5, 10]"
          :page-size="listQuery.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <el-dialog
        :title="form.id ? '修改' : '添加'"
        :visible.sync="dialogFormVisible"
        >
        <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
          <el-form-item label="昵称" label-width="100px" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="头像" label-width="100px" prop="avatar">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleUploadSuccess"
              :headers="imgUploadHeaders"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addOrUpdate">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { addSystemUserRequest, getUserList, updateSystemUserRequest } from '../../network/user'

export default {
  name: 'SystemUser',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        isSystemUser: 1
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: false,
      // 收集签到规则信息
      form: {
        avatar: '',
        name: ''
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        avatar: [{ required: true, message: '请选择头像' }]
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      getUserList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('列表获取失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          if (item.avatar.includes('http')) {
            item.imageUrl = item.avatar
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.avatar
          }
        }
        this.total = result.data.total
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { avatar: '', name: '' }
      this.imageUrl = ''
    },
    update (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.avatar
    },
    add2Srv () {
      addSystemUserRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加失败', 'error')
        }
        this.alertMessage('添加成功', 'success')
        this.getList()
      })
    },
    update2Srv () {
      console.log('this---s form:', this.form)
      updateSystemUserRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改失败', 'error')
        }
        this.alertMessage('修改成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.avatar = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.avatar
    },
    genQrCode (row) {
      this.$router.push({
        path: '/qrCode',
        query: { userId: row.userId }
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
